import { defineStore } from "pinia";
import vips from "@/assets/data/vip.json";
import insiders from "@/assets/data/insiders.json";
import free from "@/assets/data/free.json";
import tickets from "@/assets/data/tickets.json";

export const useArchiveStore = defineStore("archive", {
  state: () => ({
    CURRENT_VIP: {
      date: "26.08.2024",
      time: "16:00",
    },
    CURRENT_INSIDERS: [
      {
        date: "24.08.2024",
      },
      {
        date: "25.08.2024",
      },
    ],
    CURRENT_TICKETS: [
      {
        date: "",
      },
      {
        date: "",
      },
    ],
    vips: vips,
    freeTips: free,
    insiders: insiders,
    tickets: tickets,
  }),
  getters: {
    getVips: (state) => state.vips,
    getCurrentVip: (state) => state.CURRENT_VIP,
    getFreeTips: (state) => state.freeTips,
    getInsiders: (state) => state.insiders,
    getTickets: (state) => state.tickets,
    getCurrentInsiders: (state) => state.CURRENT_INSIDERS,
    getCurrentTickets: (state) => state.CURRENT_TICKETS,
  },
  actions: {},
});
